<template>
  <section class="register">
    <div class="container-scroller">
      <div class="container-fluid page-body-wrapper full-page-wrapper">
        <div class="content-wrapper d-flex align-items-center auth">
          <div class="row w-100 flex-grow">
            <div class="col-xl-4 col-lg-6 mx-auto">
              <div class="auth-form-light text-left p-4">
                <div class="brand-logo">
                  <img src="../../assets/images/logo-new.png" />
                </div>
                <h4>New here?</h4>
                <h6 class="font-weight-light pb-3">
                  Signing up is easy. It only takes a few steps
                </h6>
                <form @submit.prevent="signUp">
                  <div class="form-group">
                    <label for="first_name">First Name</label>
                    <input
                      type="text"
                      v-model="user.first_name"
                      id="first_name"
                      name="first_name"
                      class="form-control form-control-lg"
                      :class="{
                        'is-invalid': submitted && $v.user.first_name.$error,
                      }"
                    />
                    <div
                      v-if="submitted && !$v.user.first_name.required"
                      class="invalid-feedback"
                    >
                      First Name is required
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="last_name">Last Name</label>
                    <input
                      type="text"
                      v-model="user.last_name"
                      id="last_name"
                      name="last_name"
                      class="form-control form-control-lg"
                      :class="{
                        'is-invalid': submitted && $v.user.last_name.$error,
                      }"
                    />
                    <div
                      v-if="submitted && !$v.user.last_name.required"
                      class="invalid-feedback"
                    >
                      Last Name is required
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="lastName">User Name</label>
                    <input
                      type="text"
                      v-model="user.username"
                      id="username"
                      name="username"
                      class="form-control form-control-lg"
                      :class="{
                        'is-invalid': submitted && $v.user.username.$error,
                      }"
                    />
                    <div
                      v-if="submitted && !$v.user.username.required"
                      class="invalid-feedback"
                    >
                      User Name is required
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="email">Email</label>
                    <input
                      type="email"
                      v-model="user.email"
                      id="email"
                      name="email"
                      class="form-control form-control-lg"
                      :class="{
                        'is-invalid': submitted && $v.user.email.$error,
                      }"
                    />
                    <div
                      v-if="submitted && $v.user.email.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.user.email.required"
                        >Email is required</span
                      >
                      <span v-if="!$v.user.email.email">Email is invalid</span>
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="password">Password</label>
                    <input
                      type="password"
                      v-model="user.password"
                      id="password"
                      name="password"
                      class="form-control form-control-lg"
                      :class="{
                        'is-invalid': submitted && $v.user.password.$error,
                      }"
                    />
                    <div
                      v-if="submitted && $v.user.password.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.user.password.required"
                        >Password is required</span
                      >
                      <span v-if="!$v.user.password.minLength"
                        >Password must be at least 9 characters</span
                      >
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="password_confirmation">Confirm Password</label>
                    <input
                      type="password"
                      v-model="user.password_confirmation"
                      id="password_confirmation"
                      name="password_confirmation"
                      class="form-control form-control-lg"
                      :class="{
                        'is-invalid':
                          submitted && $v.user.password_confirmation.$error,
                      }"
                    />
                    <div
                      v-if="submitted && $v.user.password_confirmation.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.user.password_confirmation.required"
                        >Confirm Password is required</span
                      >
                      <span
                        v-else-if="
                          !$v.user.password_confirmation.sameAsPassword
                        "
                        >Passwords must match</span
                      >
                    </div>
                  </div>
                  <div class="form-group">
                    <button
                      class="btn btn-block btn-gradient-primary btn-lg font-weight-medium auth-form-btn"
                    >
                      Register
                    </button>
                    <div class="text-center mt-4 font-weight-light">
                      Already have an account?
                      <router-link to="/auth-pages/login" class="text-primary"
                        >Login</router-link
                      >
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { required, email, minLength, sameAs } from "vuelidate/lib/validators";
import { authApi } from "../../api";

import { showErrorMessage } from "../../utils/errorHandler";
export default {
  name: "register",
  data() {
    return {
      user: {
        first_name: "",
        last_name: "",
        email: "",
        username: "",
        password: "",
        password_confirmation: "",
      },
      submitted: false,
    };
  },
  components: {},
  validations: {
    user: {
      first_name: { required },
      last_name: { required },
      username: { required },
      email: { required, email },
      password: { required, minLength: minLength(9) },
      password_confirmation: { required, sameAsPassword: sameAs("password") },
    },
  },
  methods: {
    async signUp() {
      this.submitted = true;

      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      const { status, data } = await authApi.signUp({
        ...this.user,
      });

      if (status == 200) {
        this.$swal({
          type: "success",
          title: "Success",
          text: data.messages,
        });
        this.$router.push("/auth-pages/login");
      }
    },
  },
};
</script>
